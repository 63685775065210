<template>
  <div
    class="boxShadow w-full z-[9999] min-w-[364px] max-w-[364px] md:min-w-[426px] md:max-w-[426px] -right-[140%] overflow-hidden md:right-0 mt-2 flex absolute bg-white dark:bg-neutral-900 rounded-xl  transition-all ease-in-out duration-700 delay-75 border border-neutral-200 dark:border-neutral-700"
  >
    <div @click.stop="" class="flex flex-col w-full overflow-y-auto">
      <div
        class="px-6 flex justify-between items-center py-5 w-full border-b border-neutral-200 dark:border-neutral-700 h-fit max-w-[426px]"
      >
        <div>
          <h4
            class="text-lg leading-7 font-bold text-[#101828] dark:text-neutral-200"
          >
            Usage Stats
          </h4>
        </div>
        <div
          class="py-0.5 px-1.5 font-medium text-xs/[18px] capitalize rounded-md border border-neutral-200 dark:border-neutral-700 flex items-center gap-1 text-neutral-600 dark:text-neutral-400"
        >
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4" cy="4" r="3" fill="#6172F3" />
          </svg>
          {{ getaccountLevelString(accountLevelString?.toLowerCase()) }}
        </div>
      </div>
      <div class="flex justify-between w-full items-center px-5 py-3">
        <ReusableUITabsInnerTabs
          :allTabs="allTabOptions"
          :currentTab="currentTab"
          @setCurrentTab="setCurrentTab"
          fullWidth="true"
          customPadding="px-3 py-2"
        />
      </div>
      <div v-if="currentTab != 'History'">
        <div
          class="flex flex-col items-center px-6 border-neutral-200 dark:border-neutral-700"
        >
          <!-- <ProgressBarsCircularProgress
            :progress="getOverallPercentage()"
            :text_progress="false"
            :usage_progress="true"
            :text-dark="true"
            :fullWidth="true"
          /> -->
          <!-- <div
            class="mt-4 text-center text-base leading-6 font-semibold text-[#101828] dark:text-neutral-200"
          >
            {{
              getOverallPercentage() < 40
                ? `Actions Used`
                : " You’ve almost reached your limit"
            }}
          </div>
          <div
            class="mt-0 text-center text-sm leading-5 font-normal text-[#475467] dark:text-neutral-400"
          >
            Upgrade your plan now to increase the limits
          </div> -->
        </div>
        <ChartsRadialChart />
        <div class="flex flex-col gap-6">
          <div class="text-center">
            <p
              class="font-semibold text-sm text-neutral-800 dark:text-neutral-200"
            >
              Your usage for Aevent Platform
            </p>
            <p
              class="font-normal text-xs text-neutral-600 dark:text-neutral-400"
            >
              Upgrade your plan now to increase the limits
            </p>
          </div>
          <div class="flex flex-col gap-3 p-6 pt-0">
            <div class="flex flex-col gap-0.5">
              <p
                class="font-medium text-sm text-neutral-800 dark:text-neutral-200"
              >
                Actions
              </p>
              <div class="bg-gray-100 h-2 rounded-xl">
                <div
                  :style="
                    fillerStyles(
                      (getResourceStatus.actions /
                        (userActionLimit == '-1' ? 0 : userActionLimit)) *
                        100
                    )
                  "
                  class="h-1.5 max-w-[100%] rounded-xl"
                ></div>
              </div>
              <h4
                class="font-medium text-sm text-neutral-700 dark:text-neutral-300 w-full flex justify-end mt-1.5"
              >
                {{ getResourceStatus.actions }}/{{
                  userActionLimit == "-1" ? "Unlimited" : userActionLimit
                }}
              </h4>
            </div>
            <div class="flex flex-col gap-0.5">
              <p
                class="font-medium text-sm text-neutral-800 dark:text-neutral-200"
              >
                Webinar Hours
              </p>

              <div class="bg-gray-100 h-2 rounded-xl">
                <div
                  v-if="awebinarUser"
                  :style="fillerStyles((recurringTimelines / 3) * 100)"
                  class="bg-gray-100 max-w-[100%] h-2 rounded-xl"
                ></div>
                <div
                  v-else
                  :style="
                    fillerStyles(
                      (webinarHoursUsed /
                        (userHourLimit == '-1' ? 0 : userHourLimit)) *
                        100
                    )
                  "
                  class="bg-gray-100 max-w-[100%] h-2 rounded-xl"
                ></div>
              </div>
              <h4
                class="font-medium text-sm text-neutral-700 dark:text-neutral-300 w-full flex justify-end mt-1.5"
              >
                {{ awebinarUser ? recurringTimelines : webinarHoursUsed }}/
                <p v-if="awebinarUser">
                  {{ "3" }}
                </p>
                <p v-else>
                  {{ userHourLimit == -1 ? "Unlimited" : userHourLimit }}
                </p>
              </h4>
            </div>

            <div class="flex flex-col gap-0.5">
              <p
                class="font-medium text-sm text-neutral-800 dark:text-neutral-200"
              >
                Storage
              </p>
              <div class="bg-gray-100 h-2 rounded-xl">
                <div
                  :style="
                    fillerStyles(
                      (media / (storageLimitGB == '-1' ? 0 : storageLimitGB)) *
                        100
                    )
                  "
                  class="h-1.5 max-w-[100%] rounded-xl"
                ></div>
              </div>
              <h4
                class="font-medium text-sm text-neutral-700 dark:text-neutral-300 w-full flex justify-end mt-1.5"
              >
                {{ media }} GB/{{
                  storageLimitGB == "-1" ? "Unlimited" : storageLimitGB + "GB"
                }}
              </h4>
            </div>
            <div class="flex flex-col gap-0.5">
              <p
                class="font-medium text-sm text-neutral-800 dark:text-neutral-200"
              >
                Timelines
              </p>
              <div class="bg-gray-100 h-2 rounded-xl">
                <div
                  :style="
                    fillerStyles(
                      ((Object.keys(timelinesList)?.length
                        ? Object.keys(timelinesList)?.length
                        : 0) /
                        (userTimelineLimit == '-1'
                          ? 'Unlimited'
                          : userTimelineLimit)) *
                        100
                    )
                  "
                  class="h-1.5 max-w-[100%] rounded-xl"
                ></div>
              </div>
              <h4
                class="font-medium text-sm text-neutral-700 dark:text-neutral-300 w-full flex justify-end mt-1.5"
              >
                {{
                  Object.keys(timelinesList)?.length
                    ? Object.keys(timelinesList)?.length
                    : 0
                }}/{{
                  userTimelineLimit == "-1" ? "Unlimited" : userTimelineLimit
                }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="p-3 py-10"></div> -->
      <div v-else class="px-5 pt-2">
        <div class="flex flex-col gap-3">
          <div class="flex flex-col gap-2">
            <div class="flex gap-2">
              <div class="flex flex-col gap-3 w-full startDatePicker">
                <p
                  class="font-semibold text-sm leading-none text-neutral-800 dark:text-white"
                >
                  Start Date
                </p>
                <div
                  class="rounded-md border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 transition-all ease-in-out duration-150"
                >
                  <VueDatePicker
                    :dark="colorMode.preference === 'dark' ? true : false"
                    placeholder="Select Start Date"
                    input-class="p-4"
                    wrapper-class="p-4"
                    :enable-time-picker="false"
                    auto-apply
                    v-model="state.startDate"
                    :max-date="new Date()"
                    :format="formatCalendarDate"
                    utc
                    week-start="0"
                  ></VueDatePicker>
                </div>
              </div>
              <div class="flex flex-col gap-3 w-full endDatePicker">
                <p
                  class="font-semibold text-sm leading-none text-neutral-800 dark:text-white"
                >
                  End Date
                </p>
                <div
                  class="rounded-md border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 transition-all ease-in-out duration-150"
                >
                  <VueDatePicker
                    :dark="colorMode.preference === 'dark' ? true : false"
                    placeholder="Select End Date"
                    input-class="p-4"
                    wrapper-class="p-4"
                    :enable-time-picker="false"
                    auto-apply
                    v-model="state.endDate"
                    :max-date="new Date()"
                    :format="formatCalendarDate"
                    :min-date="state.startDate"
                    utc
                    week-start="0"
                  ></VueDatePicker>
                </div>
              </div>
            </div>
            <ReusableUIButtonsButtonPrimary
              size="small"
              text="Calculate"
              :loading="loading"
              class="max-h-[34px]"
              @click="handleClick()"
            />
          </div>
          <div class="flex items-center gap-2.5">
            <p
              v-for="item in historyOptions"
              @click="handleHistory(item.type), (selectedOption = item.type)"
              class="text-xs/none p-2 text-blue-500 dark:text-blue-300 border border-blue-500 dark:border-blue-400 rounded-full cursor-pointer"
              :class="
                selectedOption === item.type &&
                'bg-blue-50 text-blue-700 border-blue-700 dark:bg-blue-400/10'
              "
            >
              {{ item.title }}
            </p>
          </div>
        </div>
        <div class="customWidth py-3">
          <ChartsBarChart
            :chartData="data"
            customWidth="!w-[98%]"
            :chartOptions="chartOptions"
          />
        </div>
      </div>
      <div
        class="py-4 gap-2 px-6 flex justify-end border-t items-center border-neutral-200 dark:border-neutral-700"
        :class="{ 'mt-2': currentTab === 'History' }"
      >
        <ReusableUIButtonsButtonSecondary
          size="medium"
          text="Upgrade plan"
          link="true"
          navigateTo="/settings"
          iconPosition="left"
          :icon="`/icons/zap.png`"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore, ACCOUNT_LEVEL } from "~~/store/auth";
import { useMediaStore } from "~~/store/media";
import { useTimelineStore } from "~~/store/timeline";
const authStore = useAuthStore();
const mediaStore = useMediaStore();
const timelineStore = useTimelineStore();
const { timelinesList } = storeToRefs(timelineStore);
import VueDatePicker from "@vuepic/vue-datepicker";
import dayjs from "dayjs";

const {
  getResourceStatus,
  userActionLimit,
  userHourLimit,
  userActionUsed,
  webinarHoursUsed,
  recurringTimelines,
  awebinarUser,
  storageLimitGB,
  userTimelineLimit,
  getResourceHistory,
  accountLevelString,
} = storeToRefs(authStore);
const colorMode = useColorMode();
const media = ref(mediaStore.totalMediaSize(1));
const usageOpen = ref(false);
const tracking_period = ref("last3Months");
let today = new Date();
const loading = ref(false);
let last3Months = new Date(
  today.getFullYear(),
  today.getMonth() - 3,
  today.getDate()
);

const state = reactive({
  startDate: last3Months,
  endDate: new Date(),
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const updateMonths = () => {
  const start = new Date(state.startDate);
  const end = new Date(state.endDate);
  const daysBetween = [];
  if (end.getTime() - start.getTime() < 30 * 24 * 60 * 60 * 1000) {
    let currentDate = new Date(start);
    while (currentDate <= end) {
      daysBetween.push(currentDate.getDate().toString());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysBetween;
  }

  const monthsBetween = [];
  let current = new Date(start);
  while (current <= end) {
    monthsBetween.push(months[current.getMonth()]);
    current.setMonth(current.getMonth() + 1);
  }
  return monthsBetween;
};
const selectedOption = ref("3Months");
const historyOptions = [
  {
    id: 1,
    type: "lastWeek",
    title: "Last Week",
  },
  {
    id: 2,
    type: "15Days",
    title: "Last 15 Days",
  },
  {
    id: 3,
    type: "lastMonth",
    title: "Last Month",
  },
  {
    id: 4,
    type: "3Months",
    title: "Last 3 Months",
  },
];
const formatDate = (dateObject) => dayjs(dateObject).format("ddd MMM DD YYYY");
const handleHistory = async (type) => {
  state.endDate = new Date();
  switch (type) {
    case "lastWeek":
      var today = new Date();
      var lastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      );
      state.startDate = lastWeek;
      data.value.labels = updateMonths();
      break;
    case "15Days":
      var today = new Date();
      var last15Days = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 15
      );
      state.startDate = last15Days;
      data.value.labels = updateMonths();
      break;
    case "lastMonth":
      var today = new Date();
      var lastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      state.startDate = lastMonth;
      data.value.labels = updateMonths();
      break;
    case "3Months":
      var today = new Date();
      var last3Months = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );
      state.startDate = last3Months;
      data.value.labels = updateMonths();
      break;
  }

  let response = await authStore.fetchResourceHistory(
    `endDate=${formatDate(state.endDate)}&&startDate=${formatDate(
      state.startDate
    )}`
  );
  if (response) {
    data.value.datasets[0].data = response.success;
  }
};
const handleClick = async () => {
  loading.value = true;
  data.value.labels = updateMonths();

  selectedOption.value = "";
  let response = await authStore.fetchResourceHistory(
    `endDate=${formatDate(state.endDate)}&&startDate=${formatDate(
      state.startDate
    )}`
  );

  if (response) {
    data.value.datasets[0].data = response.success;
    loading.value = false;
  }
};
onBeforeMount(async () => {
  await authStore.fetchPurchases();
});
const fillerStyles = (percentage) => {
  return {
    height: "100%",
    width: `${percentage == Infinity || isNaN(percentage) ? 0 : percentage}%`,
    backgroundColor: `${percentage > 90 ? "#ef5555" : "#2563eb"}`,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    textAlign: "right",
  };
};
const allTabOptions = ["Usage", "History"];
const currentTab = ref("Usage");
const setCurrentTab = (tab) => {
  currentTab.value = tab;
};
const dropdownOptions = ref({
  monthly: { title: "Monthly", value: "monthly" },
  daily: { title: "Daily", value: "daily" },
});
const getOverallPercentage = () => {
  let total_value = 400;

  let actions_percentage =
    (getResourceStatus.value.actions /
      (userActionLimit.value == "-1" ? 0 : userActionLimit.value)) *
    100;

  let storage_percentage =
    (media.value / (storageLimitGB.value == "-1" ? 0 : storageLimitGB.value)) *
    100;
  let timeline_percentage =
    (Object.keys(timelinesList.value)?.length && userTimelineLimit.value != "-1"
      ? Object.keys(timelinesList.value)?.length
      : 0 / (userTimelineLimit.value == "-1" ? 0 : userTimelineLimit.value)) *
    100;

  let webinar_percentage = 0;
  if (awebinarUser.value) {
    webinar_percentage = (recurringTimelines.value / 3) * 100;
  } else {
    webinar_percentage =
      (webinarHoursUsed.value /
        (userHourLimit.value == "-1" ? 0 : userHourLimit.value)) *
      100;
  }

  let combined_percentage =
    (isNaN(actions_percentage) || actions_percentage == Infinity
      ? 0
      : actions_percentage) +
    (isNaN(webinar_percentage) || webinar_percentage == Infinity
      ? 0
      : webinar_percentage) +
    (isNaN(storage_percentage) || storage_percentage == Infinity
      ? 0
      : storage_percentage) +
    (isNaN(timeline_percentage) || timeline_percentage == Infinity
      ? 0
      : timeline_percentage > 100
      ? 100
      : timeline_percentage);

  let storage_used = (combined_percentage / total_value) * 100;
  let manage_Store =
    Math.round(storage_used) > 100 ? 100 : Math.round(storage_used);

  return manage_Store;
};

const getaccountLevelString = (val) => {
  switch (val) {
    case "manual":
      return "Basic";

    case "beginner":
      return "Premium";

    case "professional":
      return "Enterprise";

    default:
      return val;
  }
};

onBeforeMount(async () => {
  let response = await authStore.fetchResourceHistory(
    `endDate=${formatDate(state.endDate)}&&startDate=${formatDate(
      state.startDate
    )}`
  );

  if (response) {
    data.value.datasets[0].data = response.success;
  }
  tracking_period.value = "last3Months";
});

const data = ref({
  labels: updateMonths(),

  datasets: [
    {
      data: getResourceHistory.value,
      label: "Actions Usage",
      backgroundColor: ["rgb(37,99,235)"],
      borderColor: ["rgb(37,99,235)"],
      borderWidth: 1,
    },
  ],
});
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};
</script>
