<template>
  <div class=" w-full md:w-[400px]">
    <apexchart
      type="radialBar"
      :options="chartOptions"
      :series="series"
      width="100%"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useAuthStore } from "~~/store/auth";
const authStore = useAuthStore();
const { graph_data } = storeToRefs(authStore);
const series = ref([70, 60, 50, 40]);
const chartOptions = ref({
  chart: {
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: "12px",
        },
        value: {
          fontSize: "16px",
          fontWeight: "700",
        },
        total: {
          show: true,
          fontSize: "12px",
          color: "#3B82F6",
          label: "Actions",
          formatter: function (w) {
            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
            return graph_data.value.series[0];
          },
        },
      },
    },
  },
  labels: ["Actions", "Hours", "Storage", "Timeline"],
  colors: ["#3B82F6", "#F59E0B", "#FACC15", "#14B8A6"],
  tooltip: {
    enabled: true,
    y: {
      formatter: function (val) {
        return val + "%";
      },
    },
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#be123c",
    },
  },
});
onMounted(() => {
  series.value = graph_data.value.series;
  chartOptions.value.labels = graph_data.value.labels;
});
</script>
