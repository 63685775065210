<template>
  <div id="notifications" class="pointer-events-none">
      <a
        v-if="toastLink"
        class="font-bold text-base capitalize text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
        :href="toastLink"
        target="_blank"
      >
        <ReusableUIToaster
            :showToast="showToast"
            :label="toastLabel"
            :message="toastMessage"
            :status="'link'"
            class="pointer-events-auto"
        />
      </a>
      <button
        v-else-if="toastDownload"
        class="font-bold text-base capitalize text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
        @click="startDownload"
        >
        <ReusableUIToaster
            :showToast="showToast"
            :label="toastLabel"
            :message="toastMessage"
            :status="'link'"
            class="pointer-events-auto"
        />
      </button>
    <ReusableUIToaster
      v-else
      :showToast="showToast"
      :label="toastLabel"
      :message="toastMessage"
      :status="'info'"
      class="pointer-events-auto"
    />
  </div>
</template>

<script setup>
import { useAuthStore } from "~~/store/auth";
import { useIntegrationStore } from "~~/store/integration";

// import { retryDownloadFile } from "~~/utils/helpers";
const authStore = useAuthStore();
const integrationStore = useIntegrationStore();
const config = useRuntimeConfig();

const downloadCSV = (downloadKey, pType = null, downloadTitle = null) => {
  const fileName = downloadTitle ? `export_${pType}_${downloadTitle}.csv` : downloadKey;
  downloadFile(
    authStore.getUser.api_token,
    `${config.public.apiBaseURL}/webinar/download?downloadKey=${downloadKey}`,
    fileName
  );
};

const subscription = ref(null);

const subscribeEvents = async () => {
  if (subscription.value) return;
  const newNotifications = window.Parse.Object.extend("Notifications");
  const query = new window.Parse.Query(newNotifications);
  query?.equalTo("userId", authStore.getUser?.user_uid || "");
  subscription.value = await query?.subscribe();
  subscription.value.on("create", async (payload) => {
    if (payload.get("color") === "invisible") {
      let title = payload.get("title");
      if (title === "downloadReady") {
        setTimeout(
          () =>
            downloadCSV(
              payload.get("body"),
              payload.get("type"),
              payload.get("nodeId")
            ),
          5000
        );
      } else if (title === "intercomChecklist") {
        if (window.Intercom) {
          window.Intercom("startChecklist", payload.get("nodeId"));
        }
      } else if (title === "refreshIntegration") {
        const integrationID = payload.get("nodeId");
        const integrationType = payload.get("type");
        const refreshedIntegration = await integrationStore.refreshIntegration(
          integrationType,
          integrationID
        );
        integrationStore.$patch({
          integrations: {
            ...integrationStore.integrations,
            integrationID: refreshedIntegration,
          },
        });
      }
    } else {
      if (payload.get("type") === "export") {
        toastDownload.value = [payload.get("nodeId"), payload.get("data")];
      }
      notificationToast(
        payload.get("title"),
        payload.get("body"),
        payload.get("type") === "analytics" ? `/#/?${payload.id}` : "",
        payload.get("type") !== "analytics" && payload.get("type") !== "export"
      );
    }
  });
};

const unsubscribe = async () => {
  if (!subscription.value) return;
  await subscription.value.unsubscribe();
  subscription.value = null;
};

onBeforeMount(async () => {
  const initializeParse = setInterval(() => {
    if (window !== undefined && window?.Parse && authStore.getUser?.email) {
      try {
        if (typeof window.Parse !== "undefined") {
          window.Parse.initialize(config.public.parseAppID);
          window.Parse.serverURL = config.public.parseServerURL;
          if (!window?.Parse?.User?.current()) {
            window.Parse.User.logIn(
              authStore.getUser.user_uid,
              authStore.getUser.support_token,
            );
          }
          clearInterval(initializeParse);
          subscribeEvents();
        }
      } catch (e) {
        console.error("Could not connect to realtime: ", e);
      }
    }
    clearInterval(initializeParse);
    subscribeEvents();
  }, 1000);
});

onBeforeUnmount(() => {
  unsubscribe();
});

const showToast = ref(false);
const toastLabel = ref("");
const toastMessage = ref("");
const toastLink = ref("");
const notificationToast = (label, message, link = "", hide = true) => {
  showToast.value = true;
  toastLabel.value = label;
  toastMessage.value = message;
  toastLink.value = link;
  if (hide) {
    setTimeout(() => {
      showToast.value = false;
    }, 4000);
  }
};

const toastDownload = ref(null);
const startDownload = async () => {
  if (!toastDownload.value) return;
  const [filename, data] = toastDownload.value;
  if (! data) {
    downloadCSV(filename);
  }
  if (! data.compressed) return;
  const compressed = data.compressed;
  const link = document.createElement("a");
  const blob = new Blob([decompress(compressed)], { type: 'text/csv' });
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  toastDownload.value = null;
}
</script>

<style>
#notifications {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 0px;
  overflow: visible;
  height: 50vh;
  padding-right: 200px;
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 0px;
  gap: 15px;
}
#notifications > div {
  position: relative !important;
  margin-right: 200px;
}
</style>
