<template>
  <div>
    <div>
      <div
        class="fixed bottom-[140px] bg-neutral-800 rounded-full p-1 left-6 z-[999999] text-white cursor-pointer flex mb-3"
      >
        <img
          @click="showPortal = !showPortal"
          :src="`${cdnURL}sp_action_open.svg`"
          alt="setting_icons"
          class="h-10 w-10"
        />
      </div>

      <!-- @click.stop="showSettingsModel = !showSettingsModel" -->
    </div>
    <!-- v-if="showPortal" -->
    <div
      class="fixed bottom-[140px] left-24 text-white text-sm leading-none bg-neutral-800 rounded-md flex flex-col divide-y divide-neutral-700 w-full max-w-[236px] transition-all ease-in-out duration-400 z-[999999999]"
      :class="showPortal ? 'scale-up-br' : 'scale-down-br'"
    >
      <div
        class="px-6 flex items-center justify-between relative py-5 font-semibold"
      >
        <ReusableUIIntercomTooltip
          type="support-portal"
          title="Support Portal"
          styling="text-white text-sm leading-none"
        />
        <img
          v-if="showPortal"
          @click="showPortal = false"
          class="top-2 right-2 cursor-pointer z-10 absolute"
          :src="`${cdnURL}sp_action_close.svg`"
          alt="setting_icons"
        />
      </div>
      <div class="px-5 py-6">
        <ul class="flex flex-col gap-4">
          <li
            class="flex items-center gap-3 cursor-pointer"
            @click="handleSupport('reset')"
          >
            <img :src="`${cdnURL}sp_link.svg`" alt="setting_icons" />
            <p>Reset Support Link</p>
          </li>
          <li
            class="flex items-center gap-3 cursor-pointer"
            @click="handleSupport('copy')"
          >
            <img :src="`${cdnURL}sp_copy.svg`" alt="setting_icons" />
            <p>Copy Support Link</p>
          </li>
          <li
            class="flex items-center gap-3 cursor-pointer"
            @click="handleSupport('open')"
          >
            <img :src="`${cdnURL}sp_target.svg`" alt="setting_icons" />
            <p>Open Support</p>
          </li>
        </ul>
      </div>
    </div>
    <ReusableUIToaster
      :showToast="showToast"
      :label="toastMessage"
      :message="toastLabel"
      :status="toastStatus"
    />
  </div>
</template>
<script setup>
// imports
import { Clipboard } from "v-clipboard";
import { useCommonStore } from "~~/store/common";
import { useAuthStore } from "~~/store/auth";
import { storeToRefs } from "pinia";

// Store management
const commonStore = useCommonStore();
const authStore = useAuthStore();

//
const { getUser } = storeToRefs(authStore);
const config = useRuntimeConfig();
const supportURL = config.public.supportURL;

const cdnURL = config.public.cdnURL;
const showPortal = ref(false);
const showToast = ref(false);
const toastMessage = ref("");
const toastLabel = ref("");
const toastStatus = ref("info");
let supportLink = null;

const handleSupport = async (el) => {
  showPortal.value = false;
  supportLink = getUser.value.support_token;
  switch (el) {
    case "reset":
      const res = await commonStore.refreshSupportToken();
      if (res["success"]) {
        supportLink = res["success"];
        Clipboard.copy(
          `${supportURL}${getUser.value["tenant_user"]["tenant"]["tenant_uid"]}/support/dashboard?access_token=${supportLink}`
        );
        toastMessage.value = "Success";
        handleToaster("reset_success");
      }
      break;
    case "copy":
      if (!supportLink) {
        await authStore.validate();
        supportLink = getUser.value.support_token;
        Clipboard.copy(
          `${supportURL}${getUser.value["tenant_user"]["tenant"]["tenant_uid"]}/support/dashboard?access_token=${supportLink}`
        );
        toastMessage.value = "Success";
        handleToaster("copy_success");
      } else
        Clipboard.copy(
          `${supportURL}${getUser.value["tenant_user"]["tenant"]["tenant_uid"]}/support/dashboard?access_token=${supportLink}`
        );
      toastMessage.value = "Success";
      handleToaster("copy_success");
      break;

    case "open":
      if (!supportLink) {
        await authStore.validate();
        supportLink = getUser.value.support_token;
        supportLink = res["success"];
        Clipboard.copy(supportLink);
        let url = `${supportURL}${getUser.value["tenant_user"]["tenant"]["tenant_uid"]}/support/dashboard?access_token=${supportLink}`;
        // window.open(url, "Support Portal");
        // window.open(url, "support");
        window.open(url);
      } else
        window.open(
          `${supportURL}${getUser.value["tenant_user"]["tenant"]["tenant_uid"]}/support/dashboard?access_token=${supportLink}`
        );
      break;

    default:
      break;
  }
};

const handleToaster = (status, item) => {
  showToast.value = true;
  document.body.style.overflow = "hidden";

  if (status == "reset_success") {
    toastStatus.value = "success";
    toastLabel.value = `Support link was successfully reset and copied to clipboard.`;
  } else if (status == "copy_success") {
    toastStatus.value = "success";
    toastLabel.value = `Support link was successfully copied to clipboard.`;
  } else {
    toastLabel.value = `Error`;
  }

  setTimeout(() => {
    showToast.value = false;
    document.body.style.overflow = "initial";
  }, 3000);
};
</script>

<style>
#mydiv {
  position: absolute;
  z-index: 2;
  text-align: center;
  overflow: hidden;
}

#mydivheader {
  cursor: move;
  z-index: 3;
  overflow: hidden;
  color: #fff;
}
</style>
