<template>
  <div
    class="bg-black dark:bg-neutral-900 w-full hidden xl:block"
    :class="
      navView === 'full' ? '3xl:max-w-[92px] max-w-[260px]' : 'max-w-[92px]'
    "
  >
    <div
      class="bg-black dark:bg-neutral-900 border-r w-full border-transparent dark:border-neutral-700 h-screen py-6 z-[999999] hidden xl:block fixed transition-all ease-in-out duration-[10000]"
      :class="navView === 'full' ? 'max-w-[260px]  px-6 ' : 'max-w-[92px] px-3'"
    >
      <!-- <div class="absolute top-12 -right-3 z-[4] cursor-pointer">
        <div
          @click="changeNavView()"
          :class="
            navView === 'full'
              ? ' transition-all ease-in-out duration-300 rotate-180'
              : 'transition-all ease-in-out duration-300  rotate-0'
          "
          class="bg-black dark:bg-neutral-900 w-6 h-6 flex justify-center items-center rounded-full border-[1.5px] border-neutral-600"
        >
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
            d="M1.5 11L6.5 6L1.5 1"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div> -->
      <!-- aevent_logo.svg -->

      <div class="flex flex-col space-y-6">
        <NuxtLink :to="`/`">
          <img
            v-if="navView === 'compact'"
            :src="`${cdnURL}aevent_logo_new.svg`"
            alt="icon"
            class="w-12 m-auto"
            loading="lazy"
          />
          <img
            v-if="navView === 'full'"
            :src="`${cdnURL}aevent_logo_new_full.svg`"
            alt="icon"
            loading="lazy"
          />
        </NuxtLink>
        <hr class="border-neutral-700 text-black" />
      </div>
      <div class="mt-6">
        <div
          class="space-y-1 px-0.5 mb-1"
          v-for="page in pages()"
          :key="page.id"
        >
          <!-- <NuxtLink :to="`${page.route}`"> -->

          <div
            class="gap-2 text-center items-center flex px-4 py-3 hover:bg-neutral-800 rounded-md cursor-pointer"
            :class="[
              (router.path === '/' && page.label === 'index') ||
              page.label.includes(router.path) ||
              currentPage === page.label
                ? 'bg-neutral-800'
                : 'bg-transparent',
              navView === 'compact' ? 'justify-center' : 'justify-start',
            ]"
            @click="navigate(page)"
            @pointerenter="showdDataToolTip(page.name)"
            @pointerleave="showdDataToolTip()"
            :dusk="page.label"
          >
            <!-- @click="setCurrentPage(page.label)" -->
            <img
              :src="`${cdnURL}${page.icon}`"
              alt="Side Nav icon"
              style="height: unset; max-width: unset"
            />
            <p v-if="navView === 'full'" class="text-white">
              {{ page.name }}
            </p>
          </div>
          <div v-if="navView === 'compact'">
            <ReusableUISideNavTooltip
              v-if="showTooltip.page === page.name ? true : false"
              position=" left-[80px] -top-11"
              :title="page.name"
              class="w-fit"
            />
          </div>
          <!-- </NuxtLink> -->
        </div>
      </div>
    </div>
    <!-- intercom Portal -->
    <ReusableUISupportPortal
      v-if="router.path == '/' || router.path == '/?t=n'"
    />
    <div
      class="fixed bottom-[85px] bg-neutral-800 rounded-full left-[22px] z-[999999] text-white cursor-pointer w-12 h-12 flex items-center justify-center"
      @pointerenter="showTooltip.help = true"
      @pointerleave="showTooltip.help = false"
      dusk="pagetip"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
        <path d="M12 17h.01" />
      </svg>
    </div>

    <div
      class="fixed left-24 z-50 bottom-24"
      v-if="showTooltip.book || showTooltip.help"
    >
      <div
        class="bg-neutral-700 dark:bg-neutral-800 px-3 py-2 rounded shadow-2xl w-fit mx-auto scale-up-bottom"
      >
        <p
          class="font-semibold text-xs leading-none text-neutral-50 whitespace-nowrap"
        >
          {{ showTooltip.book ? "Tutorial Help" : "Tool Help" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useCommonStore } from "~~/store/common";
const commonStore = useCommonStore();
const { isActiveTab } = storeToRefs(commonStore);
const emit = defineEmits(["showWarning"]);

const config = useRuntimeConfig();
const cdnURL = config.public.cdnURL;
const currentPage = ref("index");
const route = useRouter();
const router = useRoute();
const navView = ref("");
const showTooltip = ref({
  page: "",
  book: false,
  help: false,
});
const changeNavView = () => {
  if (navView.value === "compact") {
    navView.value = "full";
    sessionStorage.setItem("navView", (navView.value = "full"));
  } else {
    navView.value = "compact";
    sessionStorage.setItem("navView", (navView.value = "compact"));
  }
};
const showdDataToolTip = (id) => {
  showTooltip.value.page = id;
};
const setCurrentPage = (id) => {
  commonStore.setActiveTab(id);
};
const navigate = (page) => {
  const tour = localStorage.getItem("tour");
  if (tour === "true") {
    route.push(`${page.route}?t=n`);
  } else {
    route.push(page.route);
  }
  setCurrentPage(page.label);
};
onMounted(() => {
  navView.value = sessionStorage.getItem("navView")
    ? sessionStorage.getItem("navView")
    : "compact";
});
watch(
  () => {
    currentPage.value = isActiveTab.value;
    if (
      route.currentRoute.value.fullPath.split("/")[1] !== "" &&
      currentPage.value === "index"
    ) {
      if (route.currentRoute.value.fullPath.split("/")[1] !== "webinar") {
        commonStore.setActiveTab(
          route.currentRoute.value.fullPath.split("/")[1]
        );
        currentPage.value = isActiveTab.value;
      } else {
        commonStore.setActiveTab("index");
        currentPage.value = isActiveTab.value;
      }
    } else if (
      route.currentRoute.value.fullPath.split("/")[1] === "" &&
      currentPage.value !== "index"
    ) {
      commonStore.setActiveTab("index");
      currentPage.value = isActiveTab.value;
    }

    // if (route.currentRoute.value.name == "index" ) {
    //   route.push("/");
    //   currentPage.value == "index";
    // }
  },
  { immediate: true }
);
</script>
