<template>
  <div v-outside>
    <div
      @click="(usageState = false), (tooltipVisible = false), (topMenu = false)"
      class="w-full max-w-[calc(100vh - 92px)] bg-white dark:bg-neutral-900 h-[62px] flex justify-between py-3.5 md:px-8 px-4 z-20 border-b border-neutral-200 dark:border-neutral-700 gap-3 lg:gap-0"
    >
      <div class="flex items-center gap-3">
        <svg
          width="32"
          class="text-neutral-700 dark:text-neutral-200 block xl:hidden cursor-pointer"
          @click="showHamBurger = !showHamBurger"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 9C4 8.44772 4.67157 8 5.5 8H26.5C27.3284 8 28 8.44772 28 9C28 9.55228 27.3284 10 26.5 10H5.5C4.67157 10 4 9.55228 4 9Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 15.5C4 14.9477 4.67157 14.5 5.5 14.5H26.5C27.3284 14.5 28 14.9477 28 15.5C28 16.0523 27.3284 16.5 26.5 16.5H5.5C4.67157 16.5 4 16.0523 4 15.5Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 22C4 21.4477 4.67157 21 5.5 21H26.5C27.3284 21 28 21.4477 28 22C28 22.5523 27.3284 23 26.5 23H5.5C4.67157 23 4 22.5523 4 22Z"
            fill="currentColor"
          />
        </svg>
        <div class="block" v-if="isAllLoaded">
          <div
            class="flex md:gap-3 gap-1"
            v-if="
              timelineData ||
              isActiveTab == 'index' ||
              route.path == '/' ||
              route.path == '/?t=n'
            "
          >
            <!-- out side he timeline can be set from here  -->
            <h1
              v-if="
                !timelineData && (route.path == '/' || route.path == '/?t=n')
              "
              class="text-lg leading-none text-neutral-800 dark:text-neutral-200 font-semibold cursor-default"
            >
              <div class="hidden md:flex items-center justify-between w-full">
                <div
                  class="text-xs md:text-[22px] md:leading-6 text-[#262626] dark:text-neutral-200 font-semibold"
                >
                  {{ greetings() }}, {{ getUser?.name?.split(" ")[0] }}
                </div>
              </div>
            </h1>
            <h1
              v-if="timelineData"
              class="text-lg text-neutral-800 font-semibold cursor-default truncate dark:text-neutral-200 max-w-[120px] md:max-w-[200px] lg:max-w-[400px] 2xl:max-w-[600px]"
            >
              <span
                :title="timelineData.general.timelineName"
                v-if="timelineData?.general?.timelineName"
                >{{ timelineData.general.timelineName }}</span
              >
              <span v-else class="flex items-center justify-center">
                <span> Loading </span>
                <div class="loader mt-4">
                  <li class="ball"></li>
                  <li class="ball"></li>
                  <li class="ball"></li>
                </div>
              </span>
            </h1>
            <img
              v-if="timelineData?.general?.timelineName"
              class="cursor-pointer"
              :src="`${cdnURL}pencil_icon_light.svg`"
              alt="edit-timeline-info"
              @click="showEditTLInfoModal = true"
            />
          </div>
        </div>
        <h1
          v-if="label !== ''"
          class="text-lg leading-none text-neutral-800 font-semibold dark:text-neutral-200"
        >
          Create Timeline
        </h1>
      </div>

      <div class="flex md:space-x-4 space-x-2 items-center">
        <div
          @click.stop="(tooltipVisible = !tooltipVisible), (loader = true)"
          class="cursor-pointer relative w-6 h-6 md:flex items-center justify-center bg-neutral-50 rounded-full dark:bg-neutral-700"
        >
          <img
            :src="`/icons/app-status.svg`"
            alt="icon"
            class="cursor-pointer w-4 h-4"
          />

          <div
            v-if="tooltipVisible"
            class="tooltip-shade flex flex-col top-12 z-50 -right-2 dark:bg-neutral-800 dark:border-neutral-800 ease-in-out w-[220px] dark:before:bg-neutral-800 dark:before:border-neutral-800 before:border-neutral-200 border-neutral-200 absolute p-4 text-xs font-medium text-neutral-700 transition-all duration-500 bg-white rounded-lg before:w-4 before:h-4 before:border-t before:border-r before:rotate-[315deg] before:-top-[8.5px] before:right-4 border border-1px-solid-[#E5E5E5] before:bg-white before:absolute"
          >
            <div>
              <span>
                <div class="relative w-full" v-if="loader">
                  <div
                    class="animate-pulse w-full absolute flex items-center ml-1 mt-2 gap-2"
                  >
                    <div
                      class="w-4 h-4 dark:bg-neutral-600 bg-neutral-200 rounded-full"
                    ></div>
                    <div
                      class="h-3 bg-neutral-200 w-[80%] dark:bg-neutral-600 rounded-full"
                    ></div>
                  </div>
                </div>
                <iframe
                  :src="`https://status.aevent.com/badge?theme=${colorMode.preference}`"
                  width="250"
                  height="30"
                  ref="myIframe"
                  frameborder="0"
                  scrolling="no"
                ></iframe
              ></span>
              <a href="https://status.aevent.com" target="_blank">
                <ReusableUIButtonsButtonSecondary
                  text="View Status"
                  class="self-end w-full !py-1.5 mt-2 !text-[11px] !px-2 !rounded"
                  size="small"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="relative">
          <div
            class="flex items-center space-x-1 border border-blue-200 dark:border-blue-400 bg-blue-50 dark:bg-blue-400/10 rounded-lg px-2 md:px-3 py-1.5 cursor-pointer"
            @click.stop="usageState = !usageState"
          >
            <svg
              width="24"
              class="text-blue-600 dark:text-[#60A5FA]"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.99988 7L3.99988 17C3.99988 19.2091 7.5816 21 11.9999 21C16.4182 21 19.9999 19.2091 19.9999 17L19.9999 7M3.99988 7C3.99988 9.20914 7.5816 11 11.9999 11C16.4182 11 19.9999 9.20914 19.9999 7M3.99988 7C3.99988 4.79086 7.5816 3 11.9999 3C16.4182 3 19.9999 4.79086 19.9999 7M19.9999 12C19.9999 14.2091 16.4182 16 11.9999 16C7.5816 16 3.99988 14.2091 3.99988 12"
                stroke="currentColor"
                stroke-width="2"
              />
            </svg>

            <p
              class="text-blue-600 dark:text-[#60A5FA] text-xs md:text-sm font-semibold"
            >
              Usage
            </p>
          </div>
          <ReusableUIModalsUsageStats v-if="usageState" />
        </div>
        <div
          v-if="colorMode.preference == 'dark'"
          class="cursor-pointer hidden w-8 h-8 md:flex items-center justify-center bg-neutral-50 rounded-full dark:bg-neutral-700"
          @click="$emit('toggleTheme', 'light')"
        >
          <svg
            width="24"
            class="text-neutral-800 dark:text-neutral-200"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 3V5.25M18.364 5.63604L16.773 7.22703M21 12H18.75M18.364 18.364L16.773 16.773M12 18.75V21M7.22703 16.773L5.63604 18.364M5.25 12H3M7.22703 7.22703L5.63604 5.63604M15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-else-if="colorMode.preference == 'light'"
          class="cursor-pointer hidden w-8 h-8 md:flex items-center justify-center bg-neutral-50 rounded-full dark:bg-neutral-700"
          @click="$emit('toggleTheme', 'dark')"
        >
          <svg
            width="24"
            class="text-neutral-800 dark:text-neutral-200"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7519 15.0019C20.597 15.4839 19.3296 15.75 18 15.75C12.6152 15.75 8.25 11.3848 8.25 5.99999C8.25 4.67039 8.51614 3.40296 8.99806 2.24805C5.47566 3.71785 3 7.19481 3 11.25C3 16.6348 7.36522 21 12.75 21C16.8052 21 20.2821 18.5243 21.7519 15.0019Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-else-if="colorMode.preference == 'dark'"
          class="cursor-pointer hidden w-8 h-8 md:flex items-center justify-center bg-neutral-50 rounded-full dark:bg-neutral-700"
          @click="$emit('toggleTheme', 'dark')"
        >
          <svg
            width="24"
            class="text-neutral-800 dark:text-neutral-200"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7519 15.0019C20.597 15.4839 19.3296 15.75 18 15.75C12.6152 15.75 8.25 11.3848 8.25 5.99999C8.25 4.67039 8.51614 3.40296 8.99806 2.24805C5.47566 3.71785 3 7.19481 3 11.25C3 16.6348 7.36522 21 12.75 21C16.8052 21 20.2821 18.5243 21.7519 15.0019Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="
            !isAllLoaded &&
            colorMode.preference != 'dark' &&
            colorMode.preference != 'light'
          "
          class="cursor-pointer hidden w-8 h-8 md:flex items-center justify-center bg-neutral-50 rounded-full dark:bg-neutral-700"
          @click="$emit('toggleTheme', 'dark')"
        >
          <svg
            width="24"
            class="text-neutral-800 dark:text-neutral-200"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7519 15.0019C20.597 15.4839 19.3296 15.75 18 15.75C12.6152 15.75 8.25 11.3848 8.25 5.99999C8.25 4.67039 8.51614 3.40296 8.99806 2.24805C5.47566 3.71785 3 7.19481 3 11.25C3 16.6348 7.36522 21 12.75 21C16.8052 21 20.2821 18.5243 21.7519 15.0019Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="border border-neutral-200 h-full"></div>
        <div class="flex md:space-x-3.5 space-x-1.5 items-center">
          <div
            class="flex justify-center items-center w-7 h-7 cursor-pointer"
            @click="showNotifications = true"
          >
            <!-- :class="
              route.path == '/notifications' && 'bg-blue-100 rounded-full'
            " -->
            <!-- @click="$emit('showWarning', '/notifications')" -->
            <svg
              :class="
                route.path == '/notifications'
                  ? 'text-blue-600 '
                  : 'text-neutral-800 dark:text-neutral-200'
              "
              class="cursor-pointer"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8558 17.0817C16.7503 16.857 18.5772 16.4116 20.31 15.7719C18.8732 14.177 17.9987 12.0656 17.9987 9.75V9.04919C17.9988 9.03281 17.9989 9.01641 17.9989 9C17.9989 5.68629 15.3126 3 11.9989 3C8.68519 3 5.9989 5.68629 5.9989 9L5.9987 9.75C5.9987 12.0656 5.12417 14.177 3.68738 15.7719C5.4203 16.4116 7.24733 16.857 9.14204 17.0818M14.8558 17.0817C13.9189 17.1928 12.9655 17.25 11.9987 17.25C11.0321 17.25 10.0788 17.1929 9.14204 17.0818M14.8558 17.0817C14.9487 17.3711 14.9989 17.6797 14.9989 18C14.9989 19.6569 13.6558 21 11.9989 21C10.342 21 8.9989 19.6569 8.9989 18C8.9989 17.6797 9.04909 17.3712 9.14204 17.0818"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="flex space-x-2.5 items-center cursor-pointer"
            @click.stop="topMenu = !topMenu"
          >
            <div
              class="bg-neutral-700 dark:bg-neutral-100 h-7.5 w-7.5 rounded-full flex justify-center items-center overflow-clip"
            >
              <div v-if="isAllLoaded && getUser?.profile?.logo !== ''">
                <img :src="getUser.profile.logo" />
              </div>

              <h4
                v-else
                class="text-lg leading-3 text-center w-full h-full rounded-full bg-neutral-400 animate-pulse"
              ></h4>
            </div>
            <div class="md:flex flex-col hidden">
              <p class="text-[11px] leading-4 text-neutral-400">Welcome</p>
              <p
                v-if="isAllLoaded"
                class="text-sm leading-none text-neutral-700 dark:text-neutral-200"
              >
                {{ getUser.name }}
              </p>
              <p
                v-else
                class="text-sm leading-4 text-neutral-700 dark:text-neutral-200 w-[46.79] h-3 bg-neutral-400 rounded-lg animate-pulse"
              ></p>
              <!-- <p
                v-else
                class="text-sm w-full h-3 rounded-sm leading-4 bg-neutral-400 font-semibold animate-pulse"
              ></p> -->
            </div>
          </div>
          <div class="items-center flex justify-center">
            <svg
              class="text-neutral-800 dark:text-neutral-400 cursor-pointer transition-all ease-in-out duration-300"
              @click.stop="topMenu = !topMenu"
              :class="topMenu ? 'rotate-180' : 'rotate-0'"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 8.25L12 15.75L4.5 8.25"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- Menu popup -->
    <div>
      <div
        class="z-20 right-6 bg-white dark:bg-neutral-800 rounded-md shadow w-[183px] h-fit absolute text-sm leading-none transform transition-all ease-in-out duration-300"
        v-if="topMenu"
      >
        <div class="flex py-1 flex-col">
          <!-- <NuxtLink :to="`/settings`"> -->
          <div
            @click="navigate('/settings')"
            class="flex gap-3 py-3 px-4 cursor-pointer items-center hover:bg-blue-50 dark:hover:bg-blue-400/10 group"
          >
            <svg
              class="text-neutral-600 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.59328 3.94013C9.68369 3.39767 10.153 3.00008 10.703 3.00008H13.2969C13.8469 3.00008 14.3162 3.39767 14.4066 3.94013L14.6201 5.22118C14.6825 5.59522 14.9325 5.90679 15.2642 6.09044C15.3384 6.13149 15.4117 6.17391 15.4841 6.21765C15.8092 6.41392 16.2045 6.47494 16.5601 6.34173L17.7769 5.88585C18.2919 5.69292 18.8709 5.90058 19.1459 6.37685L20.4429 8.62329C20.7179 9.09955 20.6082 9.70481 20.1836 10.0543L19.1793 10.8812C18.8868 11.122 18.7418 11.4938 18.7488 11.8726C18.7496 11.915 18.75 11.9575 18.75 12.0001C18.75 12.0427 18.7496 12.0851 18.7488 12.1275C18.7418 12.5063 18.8868 12.8782 19.1793 13.119L20.1836 13.9458C20.6082 14.2953 20.7179 14.9006 20.4429 15.3769L19.1459 17.6233C18.8709 18.0996 18.2919 18.3072 17.7769 18.1143L16.5601 17.6584C16.2045 17.5252 15.8092 17.5862 15.4842 17.7825C15.4117 17.8262 15.3384 17.8687 15.2642 17.9097C14.9325 18.0934 14.6825 18.4049 14.6201 18.779L14.4066 20.06C14.3162 20.6025 13.8469 21.0001 13.2969 21.0001H10.703C10.153 21.0001 9.68369 20.6025 9.59328 20.06L9.37978 18.779C9.31743 18.4049 9.06746 18.0934 8.73569 17.9097C8.66152 17.8687 8.5882 17.8262 8.51577 17.7825C8.19073 17.5862 7.7954 17.5252 7.43984 17.6584L6.22297 18.1143C5.70798 18.3072 5.12899 18.0996 4.85402 17.6233L3.55703 15.3769C3.28206 14.9006 3.39172 14.2953 3.8163 13.9458L4.82065 13.119C5.11316 12.8782 5.25815 12.5064 5.25114 12.1275C5.25035 12.0852 5.24996 12.0427 5.24996 12.0001C5.24996 11.9575 5.25035 11.915 5.25114 11.8726C5.25815 11.4938 5.11315 11.122 4.82065 10.8812L3.8163 10.0544C3.39171 9.70482 3.28206 9.09957 3.55703 8.6233L4.85402 6.37686C5.12899 5.9006 5.70798 5.69293 6.22297 5.88587L7.43982 6.34174C7.79539 6.47495 8.19072 6.41393 8.51575 6.21766C8.58819 6.17391 8.66151 6.1315 8.73569 6.09044C9.06746 5.90679 9.31743 5.59522 9.37977 5.22118L9.59328 3.94013Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.9997 12C14.9997 13.6569 13.6566 15 11.9997 15C10.3429 15 8.99973 13.6569 8.99973 12C8.99973 10.3431 10.3429 9 11.9997 9C13.6566 9 14.9997 10.3431 14.9997 12Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p
              class="font-normal text-sm text-neutral-800 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200 leading-none"
            >
              Settings
            </p>
          </div>
          <!-- Toggle -->
          <div
            class="flex py-3 px-4 cursor-pointer items-center justify-between hover:bg-blue-50 dark:hover:bg-blue-400/10 group"
          >
            <div class="flex gap-3 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="text-neutral-600 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200"
                fill="none"
                stroke="currentColor"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
              <p
                class="font-normal text-sm text-neutral-800 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200 leading-none"
              >
                Helper
              </p>
            </div>
            <ReusableUIToggleButton
              @checked="handleChecked"
              :value="getTourState"
            />
          </div>
          <!-- </NuxtLink> -->
          <NuxtLink :to="`https://help.aevent.com`" target="_blank">
            <div
              class="flex gap-3 py-3 px-4 cursor-pointer items-center hover:bg-blue-50 dark:hover:bg-blue-400/10 group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-neutral-600 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200"
                version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                x="0"
                y="0"
                viewBox="0 0 472.615 472.615"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M462.769 112.271h-28.124V91.108a9.846 9.846 0 0 0-5.981-9.056c-59.396-25.355-132.473-14.609-192.357 27.865C176.424 67.443 103.345 56.7 43.951 82.052a9.846 9.846 0 0 0-5.981 9.056v21.163H9.846c-5.437 0-9.846 4.408-9.846 9.846v272.151c0 5.438 4.409 9.846 9.846 9.846h452.923c5.438 0 9.846-4.409 9.846-9.846v-272.15c0-5.438-4.408-9.847-9.846-9.847zM19.692 384.422V131.964H37.97v231.295a9.847 9.847 0 0 0 13.711 9.055c46.12-19.688 101.816-14.764 151.25 12.108H19.692zm206.77-8.98c-35.936-22.9-75.911-34.79-114.498-34.79-18.629 0-36.932 2.771-54.301 8.413V122.232c0-.039.006-.076.006-.114s-.005-.076-.006-.114V97.768c52.517-19.684 116.197-8.676 168.799 29.315v248.359zm19.692-248.359c52.601-37.992 116.285-48.999 168.799-29.315v24.235c0 .038-.006.076-.006.114s.005.076.006.114v226.832c-53.348-17.329-115.515-7.576-168.799 26.378V127.083zm206.769 257.339h-183.24c49.435-26.872 105.13-31.795 151.251-12.108a9.846 9.846 0 0 0 13.711-9.055V131.964h18.278v252.458z"
                    fill="currentColor"
                    opacity="1"
                    data-original="white"
                  ></path>
                </g>
              </svg>
              <p
                class="font-normal text-sm text-neutral-800 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200 leading-none"
              >
                Tutorials
              </p>
            </div>
          </NuxtLink>
          <!-- <div
            v-if="authStore.expert"
            @click="navigate('/developer/tokens')"
            class="flex gap-3 py-3 px-4 cursor-pointer items-center hover:bg-blue-50 dark:hover:bg-blue-400/10 group"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="text-neutral-600 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200"
              width="24"
              height="24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"
              />
            </svg>

            <p
              class="font-normal text-sm text-neutral-800 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200 leading-none"
            >
              Developer
            </p>
          </div> -->
          <div
            class="flex gap-3 py-3 px-4 cursor-pointer items-center hover:bg-blue-50 dark:hover:bg-blue-400/10 group"
            @click="logoutUser()"
          >
            <svg
              class="text-neutral-600 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 9V5.25C15.75 4.00736 14.7426 3 13.5 3L7.5 3C6.25736 3 5.25 4.00736 5.25 5.25L5.25 18.75C5.25 19.9926 6.25736 21 7.5 21H13.5C14.7426 21 15.75 19.9926 15.75 18.75V15M18.75 15L21.75 12M21.75 12L18.75 9M21.75 12L9 12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p
              class="font-normal text-sm text-neutral-800 group-hover:text-blue-600 dark:group-hover:text-blue-400 dark:text-neutral-200 leading-none"
            >
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
    <ReusableUIModalsHamBurgerMenuMob
      v-if="showHamBurger === true"
      @renderComp="(val) => $emit('renderComp', val)"
      @toggleTheme="$emit('toggleTheme', theme)"
      @closeHamburger="showHamBurger = !showHamBurger"
      :componentToRender="componentToRender"
    />
    <ReusableUIModalsTimelineSideModalsEditTimelineInfo
      v-if="showEditTLInfoModal"
      :timelineData="timelineData"
      @closeSideBar="showEditTLInfoModal = false"
    />
    <SettingsCompsNotifications
      v-if="showNotifications"
      @closeNotifications="showNotifications = false"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~~/store/auth";
import { useCommonStore } from "~~/store/common";

const commonStore = useCommonStore();
const authStore = useAuthStore();

const colorMode = useColorMode();
const { isActiveTab, isAllLoaded } = storeToRefs(commonStore);
const showHamBurger = ref(false);
const topMenu = ref(false);
const { getUser } = storeToRefs(authStore);
const showUsername = ref(getUser);
const emit = defineEmits(["showWarning"]);
const label = ref("");
const showEditTLInfoModal = ref(false);
const config = useRuntimeConfig();
const myIframe = ref(null);
const loader = ref(true);
const cdnURL = config.public.cdnURL;
const showNotifications = ref(false);
const tooltipVisible = ref(false);
const usageState = ref(false);
const getTourState = ref(getUser.value?.profile?.tours || false);
const showType = (name) => {};
const props = defineProps({
  theme: String,
  timelineData: Object,
  componentToRender: String,
});
const route = useRoute();
const router = useRouter();
const logoutUser = () => {
  authStore.logout();
  authStore.resetStores();
  const authCookie = useCookie("token");
  authCookie.value = null;
  localStorage.removeItem("user_role_id");
  window?.Intercom?.("shutdown");
  navigateTo("/login");
};

const navigate = (pageRoute) => {
  router.push(pageRoute);
  commonStore.setActiveTab("");
};

onMounted(() => {
  if (route.fullPath == "/timelines/wizard") label.value = "Create Timeline";
});
const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        topMenu.value = false;
        tooltipVisible.value = false;
        usageState.value = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};

watch(() => {
  if (typeof window != "undefined" && window) {
    getTourState.value = getUser.value?.profile?.tours;
    if (myIframe.value) {
      myIframe.value.onload = function () {
        loader.value = false;
      };
    }
  }
});
const handleChecked = async (e) => {
  const res = await authStore.updateUserHelperTool({ tours: e });
  if (res) {
    getTourState.value = e;
  }
};
const greetings = () => {
  let time = new Date();
  let currentTime = time.getHours();
  if (currentTime < 12) {
    return "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "Good Afternoon";
  } else if (currentTime >= 18) {
    return "Good Evening";
  }
};
</script>
