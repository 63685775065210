<template>
  <div>
    <div
      class="fixed w-full inset-0 bg-black/60 backdrop-blur-sm z-30"
      @click="$emit('closeSideBar')"
    ></div>
    <div
      class="w-full max-w-[444px] fixed top-0 z-30 transition-all ease-in-out duration-300 bg-white dark:bg-neutral-900 h-[calc(100vh)] overflow-y-auto content-scroll scroll-hide"
      :class="
        isLoaded
          ? 'right-0 translate-x-0  overflow-hidden'
          : '-right-full translate-x-full overflow-auto'
      "
    >
      <div
        class="flex justify-between gap-3 items-center px-6 py-5 border-b border-neutral-200 dark:border-neutral-700"
      >
        <p
          class="font-bold text-xl leading-none text-neutral-800 dark:text-neutral-200 text-left"
        >
          Edit Timeline
        </p>
        <div>
          <svg
            width="20"
            @click="$emit('closeSideBar')"
            class="text-neutral-800 dark:text-neutral-200 cursor-pointer"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="h-[calc(100vh-136px)] overflow-y-auto">
        <div>
          <div class="p-6">
            <div
              class="flex flex-col gap-4 p-4 bg-neutral-50 dark:bg-neutral-800 rounded-xl"
            >
              <ReusableUIInput
                place-holder="Enter timeline name"
                label="Timeline Name"
                full-width="true"
                v-model="name"
              />
              <ReusableUITextArea
                label="Description"
                display-emoji="true"
                fullWidth="true"
                place-holder="Enter Description"
                :modelValue="description"
                v-model="description"
                @addKey="addKeys"
              />
              <ReusableUIDropdown
                v-if="settingsStore.general.deliveryPlatform != 'vsl'"
                label="Calendar Type"
                :options="{
                  live: { title: 'Live Calendar', value: 'live' },
                  evergreen: {
                    title: 'Evergreen Calendar',
                    value: 'evergreen',
                  },
                }"
                :value="updateCalendar"
                v-model="updateCalendar"
                @change="
                  (val) => {
                    handlecalendarchange(val);
                    updateData();
                  }
                "
                showCross="false"
              />
              <div
                class="flex flex-col gap-5 p-5 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-900 rounded-xl w-full"
                v-if="
                  settingsStore.general.deliveryPlatform != 'vsl' &&
                  updateLiveDate.toggle
                "
              >
                <h1
                  class="font-semibold text-lg/none text-neutral-800 dark:text-neutral-200"
                >
                  Date and Time
                </h1>
                <div class="flex flex-col gap-4">
                  <div class="w-full flex flex-col gap-3">
                    <p
                      class="font-semibold text-sm leading-none text-neutral-800 dark:text-white"
                    >
                      When is your live event?
                    </p>
                    <div
                      class="timerPosition rounded-md border border-neutral-300 dark:border-neutral-700 w-full lg:max-w-[396px] hover:border-neutral-400 transition-all ease-in-out duration-150 picker"
                    >
                      <VueDatePicker
                        :dark="isDarkMode ? true : false"
                        v-model="state.startDate"
                        placeholder="Select Start Date"
                        input-class="p-4"
                        wrapper-class="p-4"
                        :enable-time-picker="false"
                        position="top"
                        auto-apply
                        utc
                        week-start="0"
                      >
                      </VueDatePicker>
                    </div>
                  </div>
                  <ReusableUITimePicker
                    label="Start Time"
                    @setHours="(hours) => (state.startHours = hours)"
                    full-width="true"
                    @setMinutes="(minutes) => (state.startMins = minutes)"
                    @setAMPM="
                      (amPM) => (state.startTimeisAMorPM = amPM == 'AM' ? 1 : 2)
                    "
                    @setTime="[(time) => (startTimeRef = time)]"
                    :value="startTimeRef"
                    appearOnTop="true"
                  />

                  <ReusableUIInput
                    label="Timezone"
                    full-width="true"
                    place-holder="Enter Timezone"
                    :model-value="getUser.timezone"
                    :disabled="true"
                  />
                </div>
                <div
                  class="p-4 flex-col flex items-start gap-1 bg-neutral-50 dark:bg-neutral-800 border box-border border-neutral-200 dark:border-neutral-700 rounded-md"
                >
                  <div class="flex items-center gap-1">
                    <svg
                      width="16"
                      class="cursor-pointer text-neutral-500 dark:text-neutral-200"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.375 9.375L9.40957 9.35771C9.88717 9.11891 10.4249 9.55029 10.2954 10.0683L9.70458 12.4317C9.57507 12.9497 10.1128 13.3811 10.5904 13.1423L10.625 13.125M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 6.875H10.0063V6.88125H10V6.875Z"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p class="text-sm text-neutral-800">Remember</p>
                  </div>
                  <p
                    class="font-normal text-xs text-neutral-500 dark:text-neutral-200"
                  >
                    This date will be used as a visual reference for your
                    timeline. You can select the actual event date when you
                    schedule the event using this timeline
                  </p>
                </div>
              </div>

              <div
                v-if="warningModal"
                class="p-4 flex-col flex items-start gap-1 bg-neutral-50 dark:bg-neutral-800 border box-border border-neutral-200 dark:border-neutral-700 rounded-md"
              >
                <div class="flex items-center gap-1">
                  <svg
                    width="16"
                    class="cursor-pointer text-neutral-500 dark:text-neutral-200"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.375 9.375L9.40957 9.35771C9.88717 9.11891 10.4249 9.55029 10.2954 10.0683L9.70458 12.4317C9.57507 12.9497 10.1128 13.3811 10.5904 13.1423L10.625 13.125M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 6.875H10.0063V6.88125H10V6.875Z"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p class="text-sm text-neutral-800 dark:text-neutral-200">
                    Remember
                  </p>
                </div>
                <p
                  class="font-normal text-sm text-neutral-500 dark:text-neutral-200"
                >
                  Your live reference date will be automatically erased. You
                  will need to reselect it when switching to the live calendar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full flex items-center gap-2 py-4 border-t border-neutral-200 dark:border-neutral-700 px-6"
      >
        <ReusableUIButtonsButtonSecondary
          size="medium"
          text="Cancel"
          class="w-full"
          @click="$emit('closeSideBar')"
        />
        <ReusableUIButtonsButtonPrimary
          size="medium"
          text="Update"
          class="w-full"
          @click="handleSubmit"
          :loading="submitLoader"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useWebinarStore } from "~~/store/webinar";
import { useSettingsStore } from "~~/store/settings";
import { useAuthStore } from "~~/store/auth";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const emit = defineEmits(["closeSideBar"]);
const settingsStore = useSettingsStore();
const webinarStore = useWebinarStore();
const authStore = useAuthStore();
const warningModal = ref(false);
const { deliveryPlatforms } = storeToRefs(webinarStore);
const { getUser } = storeToRefs(authStore);
const name = ref(settingsStore.general.timelineName || "");
const description = ref(settingsStore.general.description || "");
const startTimeRef = ref("");
const submitLoader = ref(false);
const route = useRoute();
const updateCalendar = ref("");
let isLoaded = ref(false);
const updateLiveDate = ref({
  toggle: false,
  date: "",
});
const state = reactive({
  startDate: new Date(),
  startHours: "01",
  startMins: "00",
  startTimeisAMorPM: "1",
});

onMounted(() => {
  setTimeout(() => {
    isLoaded.value = true;
  }, 50);

  if (
    settingsStore.general.timeStamp == null ||
    settingsStore.general.eventType == null
  ) {
    updateCalendar.value = "evergreen";
  } else {
    updateCalendar.value = "live";

    updateLiveDate.value.toggle = true;
    updateLiveDate.value.date = new Date(settingsStore.general.timeStamp);
  }
  if (deliveryPlatforms.value["vsl"]) delete deliveryPlatforms.value["vsl"];
});

const handlecalendarchange = (val) => {
  if (val == "live") {
    updateLiveDate.value.toggle = true;
    warningModal.value = false;

    updateLiveDate.value.date = new Date(settingsStore.general.timeStamp);
  } else {
    updateLiveDate.value.toggle = false;
    warningModal.value = true;
  }
};

const getTimestamp = (param) => {
  let timeHours = 12;
  if (param.isAMorPM == 2) {
    if (parseInt(param.hours) == 12) {
      timeHours = parseInt(param.hours);
    } else {
      timeHours = parseInt(param.hours) + 12;
    }
  } else {
    if (parseInt(param.hours) == 12) {
      timeHours = 0;
    } else {
      timeHours = parseInt(param.hours);
    }
  }
  const timestamp = `${param.date.getFullYear()}/${
    param.date.getMonth() + 1
  }/${param.date.getDate()} ${timeHours}:${param.mins}`;
  return timestamp;
};

onMounted(() => {
  updateData();
});

const handleSubmit = async () => {
  submitLoader.value = true;

  if (updateCalendar.value == "live") {
    const param = {
      hours: state.startHours,
      mins: state.startMins,
      date: new Date(state.startDate),
      isAMorPM: state.startTimeisAMorPM,
    };
    const startTime = getTimestamp(param);
    settingsStore.general["timeStamp"] = startTime;
    settingsStore.general["eventType"] == null ||
    settingsStore.general["eventType"] == undefined
      ? (settingsStore.general["eventType"] = "live-webinar")
      : "";
  } else if (updateCalendar.value == "evergreen") {
    settingsStore.general.timeStamp = null;
  }

  settingsStore.general.description = description.value;
  settingsStore.general.timelineName = name.value;
  await settingsStore.setTimelinesMaster(route.params.id);
  setTimeout(() => {
    submitLoader.value = false;
    emit("closeSideBar");
  }, 500);
};

const updateData = () => {
  const timeStamp = settingsStore.general.timeStamp;

  if (timeStamp != null) {
    const [dateStr, timeStr] = timeStamp?.split(" ");
    state.startDate = new Date(dateStr);
    let time = getDateTimeStringWebinar(
      timeStamp,
      "time",
      authStore.user
    ).split(":");
    state.startHours = time[0];
    state.startMins = time[1].split(" ")[0];
    state.startTimeisAMorPM = time[1].split(" ")[1] == "PM" ? "2" : "1";
    startTimeRef.value = `${time[0].padStart(2, "0")}:${time[1]
      .split(" ")[0]
      .padStart(2, "0")} ${time[1].split(" ")[1]}`;
  }

  if (settingsStore.general.timeStamp) {
    getDateTimeStringWebinar(
      settingsStore.general.timeStamp,
      "time",
      getUser
    ).split(" ")[1] == "AM"
      ? (state.startTimeisAMorPM = 1)
      : (state.startTimeisAMorPM = 2);
  }
};
</script>
